import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { restrictIfCategoryExist, restrictIfCategoryNotExist } from '@guard/no-categories.guard';
import { FileUploaderModule } from '@modules/directives/file-uploader/file-uploader.module';
import { FolderProductsService } from '@modules/folder/services/folder-products.service';
import { FolderService } from '@modules/folder/services/folder.service';
import { canViewByPermission } from '@permissions/can-activate/guards-menu';
import { LocalizerService } from '@services/localizer/localizer.service';
import { FIELDS_SETTINGS_QUERY } from '@tokens/field-settings-query';
import { withSelectionModelType } from '@tokens/selection-model.token';
import { CreateViewService } from '@utils/strategy/services/create-view/create-view.service';
import { CategoryFieldsService } from './pages/classifier/services/category-fields/category-fields.service';
import { CategoryProductsService } from './pages/classifier/services/category-products.service';
import { ProductFieldFormService } from './pages/classifier/services/product-field-form.service';
import { ProductService } from './pages/classifier/services/product.service';
import { DashboardSettingsService } from './pages/dashboard/services/dashboard-settings/dashboard-settings.service';
import { CategoryApiService } from './pages/settings/pages/create-category/services/category-api/category-api.service';
import { StatusFormService } from './pages/settings/pages/create-status/services/status-form/status-form.service';
import { UserRolesService } from './pages/settings/pages/roles-settings/services/user-roles/user-roles.service';
import { UserFormService } from './pages/settings/pages/users-settings/services/user-form/user-form.service';
import { UsersService } from './pages/settings/pages/users-settings/services/users/users.service';
import { PROVIDERS } from './pages/strategy/strategy-providers';

export const CORE_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/routes/routes').then((r) => r.DASHBOARD_ROUTES),
    title: 'Dashboard',
    providers: [DashboardSettingsService],
    canMatch: [canViewByPermission('dashboardHide', ['matrix']), restrictIfCategoryNotExist],
  },
  {
    path: 'classifier',
    loadChildren: () => import('./pages/classifier/routes/routes').then((r) => r.CLASSIFIER_ROUTES),
    title: 'Classifier',
    resolve: { requestSettingsKey: () => 'classifierTableColumnSizes' },
    providers: [
      CategoryProductsService,
      ProductFieldFormService,
      ProductService,
      FolderService,
      FolderProductsService,
      CreateViewService,
      CategoryFieldsService,
      { provide: FIELDS_SETTINGS_QUERY, useValue: 'classifier' },
      withSelectionModelType('product'),
      importProvidersFrom(
        FileUploaderModule.forFeature(
          () => '',
          () => '',
        ),
      ),
    ],
    canMatch: [
      canViewByPermission(['classifierAndFoldersHide', 'skuPropertiesHide', 'alternativeProductsHide'], ['clustering']),
      restrictIfCategoryNotExist,
    ],
  },
  {
    path: 'clustering',
    loadChildren: () => import('./pages/clustering/clustering-routes').then((r) => r.CLUSTERING_ROUTES),
    title: 'Clustering',
    canMatch: [canViewByPermission('clusterizationHide', ['log']), restrictIfCategoryNotExist],
  },
  {
    path: 'matrix',
    loadChildren: () => import('./pages/matrix/matrix-routes').then((r) => r.MATRIX_ROUTES),
    title: 'Matrix',
    canMatch: [canViewByPermission('matrixAndMatrixHistoryHide', ['w-matrix']), restrictIfCategoryNotExist],
  },
  {
    path: 'w-matrix',
    loadChildren: () => import('./pages/warehouse-matrix/warehouse-matrix.routes'),
    title: 'Warehouses Matrix',
    canMatch: [canViewByPermission('matrixWarehousesHide', ['strategy']), restrictIfCategoryNotExist],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings-routes').then((r) => r.SETTINGS_ROUTES),
    title: 'Settings',
    data: {
      fullPage: true,
    },
    providers: [
      LocalizerService,
      CreateViewService,
      UserFormService,
      UsersService,
      StatusFormService,
      UserRolesService,
      CategoryApiService,
    ],
    canMatch: [canViewByPermission('settingsAllHide', ['user'])],
  },
  {
    path: 'statistic',
    loadChildren: () => import('./pages/statistic/statistic-routes').then((r) => r.STATISTIC_ROURES),
    title: 'Statistic',
    canMatch: [canViewByPermission('statisticHide', ['classifier']), restrictIfCategoryNotExist],
  },
  {
    path: 'strategy',
    loadChildren: () => import('./pages/strategy/strategy-routes').then((r) => r.STRATEGY_ROUTES),
    title: 'Strategy',
    providers: [PROVIDERS],
    canMatch: [canViewByPermission('strategyHide', ['statistic']), restrictIfCategoryNotExist],
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user-space/user-space-routes').then((r) => r.USER_SPACE_ROUTES),
    title: 'User profile',
    data: {
      fullPage: true,
    },
    providers: [UserRolesService],
  },
  {
    path: 'manual',
    loadComponent: () => import('./pages/user-space/components/manual-space/manual-space.component'),
    title: 'Manual',
    data: {
      fullPage: true,
    },
  },
  {
    path: 'log',
    loadChildren: () => import('./pages/log/routes'),
    title: 'Logs',
    data: {
      fullPage: true,
    },
    canMatch: [canViewByPermission('logHide', ['settings'])],
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'no-categories',
    loadComponent: () => import('./pages/no-categories/no-categories.component'),
    data: {
      fullPage: true,
    },
    canActivate: [restrictIfCategoryExist],
  },
];

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeRuExtra from '@angular/common/locales/extra/ru';
import localeUkExtra from '@angular/common/locales/extra/uk';
import localeRu from '@angular/common/locales/ru';
import localeUk from '@angular/common/locales/uk';
import { effect, inject, LOCALE_ID, Provider } from '@angular/core';
import { AppLocale } from '@type/app-locale';
import { DEFAULT_APP_LOCALE } from './language.token';
import { USER } from './user.token';

export const language: Record<string, AppLocale> = {
  ru: 'ru',
  uk: 'uk',
  en: 'en',
};

export class DynamicDatePipeLocale extends String {
  private locale = inject(USER)().company.localization;

  constructor() {
    super();

    effect(() => this.initLocales(this.locale));
  }

  private initLocales(locale: AppLocale): void {
    switch (locale.toLowerCase()) {
      case language['ru']:
        registerLocaleData(localeRu, locale, localeRuExtra);
        break;
      case language['uk']:
        registerLocaleData(localeUk, locale, localeUkExtra);
        break;
      default:
        registerLocaleData(localeEn, DEFAULT_APP_LOCALE, localeEnExtra);
        break;
    }
  }

  override toString(): string {
    return this.locale;
  }

  override valueOf(): string {
    return this.toString();
  }
}

export const DynamicDatePipeLocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: DynamicDatePipeLocale,
};

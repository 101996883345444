import { HttpClient } from '@angular/common/http';
import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { getFormatOptions } from '@constants/date-format';
import { environment } from '@environments/environment';
import { CATEGORY } from '@tokens/category.token';
import { USER } from '@tokens/user.token';
import { InsightCounterResponse, InsightItem } from '@type/insight.type';
import { joinUrls } from '@utils/urls';
import { Subject, catchError, map, of } from 'rxjs';

const MARK = 'mark';
const MARK_ALL = 'mark_all';

@Injectable({
  providedIn: 'root',
})
export class InsightService {
  private http = inject(HttpClient);

  private category = inject(CATEGORY).value;

  private localeId = inject(LOCALE_ID).toString();

  protected timeZone = inject(USER)().company.time_zone_name;

  updateInsightTrigger = new Subject<boolean>();

  getInsightList(categoryId: number) {
    const { categories, insights, index } = environment.urls;
    return this.http
      .post<InsightItem[]>(joinUrls(categories, String(categoryId), insights, index), {
        sorting: 'created_at desc',
      })
      .pipe(
        map((res) => {
          return res.map((ins) => ({
            ...ins,
            created_at: new Intl.DateTimeFormat(this.localeId, getFormatOptions('y-m-d-h-m', this.timeZone)).format(
              new Date(ins.created_at),
            ),
          }));
        }),
      );
  }

  getUnreadCounter(categoryId: number) {
    const { categories, insights, count } = environment.urls;
    return this.http
      .get<InsightCounterResponse>(joinUrls(categories, String(categoryId), insights, count))
      .pipe(catchError(() => of({ not_read_count: 0 })));
  }

  changeInsightReadState(categoryId: number, insightId: number) {
    const { categories, insights } = environment.urls;
    return this.http.put(joinUrls(categories, String(categoryId), insights, String(insightId), MARK), {});
  }

  changeAllInsightReadState(categoryId: number) {
    const { categories, insights } = environment.urls;
    return this.http.put(joinUrls(categories, String(categoryId), insights, MARK_ALL), {});
  }
}

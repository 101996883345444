import { DefaultPaginatorIntl, PAGINATOR_CONFIG, PaginatorIntlService } from '@abm/paginator';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  DATE_PIPE_DEFAULT_OPTIONS,
  DatePipe,
  DecimalPipe,
  I18nPluralPipe,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  LOCALE_ID,
  Provider,
  WritableSignal,
  effect,
  importProvidersFrom,
  inject,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';
import { AuthInterceptor } from '@interceptors/auth.interceptor';
import { ErrorResponceInterceptor } from '@interceptors/error-responce.interceptor';
import { HeaderInterceptor } from '@interceptors/header.interceptor';
import { TransformBodyInterceptor } from '@interceptors/transform-body.interceptor';
import { UrlInterceptor } from '@interceptors/url.interceptor';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CategoryService } from '@services/category/category.service';
import { UserService } from '@services/user/user.service';
import { DynamicDatePipeLocaleProvider } from '@tokens/date-pipe-locale.token';
import { DEFAULT_APP_LOCALE } from '@tokens/language.token';
import { firstValueFrom } from 'rxjs';
import { AssortmentDateAdapter } from './core/date-adapter/assortment-date-adapter';
import { USER } from '@tokens/user.token';
import { User } from '@type/user.type';

export const MY_FORMATS = {
  parse: {
    dateInput: 'shortDate',
  },
  display: {
    dateInput: 'shortDate',
  },
};

export function coreTranslationConfig(url: string) {
  return {
    defaultLanguage: DEFAULT_APP_LOCALE,
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoaderFn(url, '.json'),
      deps: [HttpClient],
    },
  };
}

const paginatorIntlFactory = () => {
  const translate = inject(TranslateService);
  const userService = inject(UserService);
  return new (class implements DefaultPaginatorIntl {
    rows: string = translate.instant('paginator.rows_label');
    of: string = translate.instant('paginator.of');

    constructor() {
      effect(() => {
        const locale = userService.userTranslation();
        this.rows = translate.instant('paginator.rows_label');
        this.of = translate.instant('paginator.of');
      });
    }
  })();
};

export function createTranslateLoaderFn(prefix: string, suffix: string) {
  return function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, prefix, suffix);
  };
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang(DEFAULT_APP_LOCALE);
    return firstValueFrom(translate.use(DEFAULT_APP_LOCALE));
  };
}

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

export const CORE_PROVIDERS: (Provider | EnvironmentProviders)[] = [
  { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TransformBodyInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorResponceInterceptor, multi: true },
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [UserService],
    useFactory: (service: UserService) => () => service.getCurrentUser(),
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [CategoryService],
    useFactory: (service: CategoryService) => () => service.getCategoryListByCurrentUser(),
  },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [TranslateService],
    multi: true,
  },
  DynamicDatePipeLocaleProvider,
  MatSnackBar,
  importProvidersFrom(TranslateModule.forRoot(coreTranslationConfig('./assets/i18n/'))),
  provideHttpClient(withInterceptorsFromDi()),
  importProvidersFrom(OverlayModule),
  provideAnimations(),
  TranslatePipe,
  DatePipe,
  DecimalPipe,
  I18nPluralPipe,
  {
    provide: MAT_DATE_LOCALE,
    useFactory: (locale: typeof LOCALE_ID) => {
      return locale.toString();
    },
    deps: [LOCALE_ID],
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  {
    provide: DateAdapter,
    useClass: AssortmentDateAdapter,
  },
  { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
  {
    provide: DATE_PIPE_DEFAULT_OPTIONS,
    useFactory: (user: WritableSignal<User>) => ({
      dateFormat: 'shortDate',
      timezone: user().company.time_zone_name,
    }),
    deps: [USER],
  },
  {
    provide: PAGINATOR_CONFIG,
    useValue: {
      hidePageInfo: false,
      pageSize: environment.perPage,
      showFirstLastButtons: true,
      showPrevNextButtons: true,
      buttonsLength: 5,
    },
  },
  {
    provide: PaginatorIntlService,
    useFactory: paginatorIntlFactory,
  },
  { provide: LocationStrategy, useClass: PathLocationStrategy },
];
